body {
margin: 0;
padding: 0;
overflow: hidden;
}

.sticky-bottom {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 70%;
  margin-bottom: 40px;
  margin-top: 3px;
  background-color: rgba(255, 255, 255, 0);
  z-index: 1;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05); /* Adjust the shadow properties as needed */
  border-radius: 8px;
}

.sticky-header {
  position: fixed;
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 20px;
  background-color: rgba(49, 49, 49, 0);
  z-index: 1;
}

.below-sticky-bottom {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 70%;
  margin-bottom: 15px;
  margin-top: 3px;
  z-index: 1;
}

.chat-container {
  width: 100vw;
  height: 100vh;
  /* margin-top: 40px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 0; */
}

.chat-box {
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding-top: 40px;
  padding-bottom: 100px;
  overflow-y: auto;
}


.user-message, .bot-message {
  width: 100%;
  display: flex;
  justify-content: center;
}

.user-message {
  background-color: white;
}

.bot-message {
  background-color: rgb(247, 247, 248);
}

.message-content {
  width: 100%;
  padding: 3px;  
}

.message-group {
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid rgb(222, 222, 223);
}

.message {
  transform: translateX(20px);
  padding: 0;
  width: 70%;
}

.playlist-save {
  padding-top: 15px;
  padding-left: 75px;
}

.bottom-shadow {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px; /* Adjust the height as needed */
  background-color: white;
  box-shadow: 0px -40px 20px rgba(255, 255, 255, 1); /* Adjust the shadow properties as needed */
}

.link-style  {
  cursor: pointer;
}

.link-style:hover {
  text-decoration: underline;
}

.example-prompts {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 120px;
  width: 70%;
  margin-top: 3px;
  background-color: rgba(255, 255, 255, 0);
  z-index: 1;
}

.custom-link {
  cursor: pointer;
  text-decoration: none;
}

.custom-link:hover {
  text-decoration: underline;
  text-decoration-color: black;
}

.header {
  width: 100%;
  height: 50px;
  top: 0;
  margin-right: 30px;
  border-bottom: 1px solid rgb(222, 222, 223);
  position: fixed;
  background-color: rgba(255, 255, 255, 1);
  z-index: 1;
}

.spotify-logo {
  top: -8px;
  left: -15px;
  position: fixed;
  background-color: rgba(255, 255, 255, 0);
  z-index: 1;
}